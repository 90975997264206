import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Player.module.scss';
import cs from 'classnames';
import formatDate from 'src/components/pages/podcasts/PodcastTile/formatDate';


let player;

class Player extends Component {
  state = {
    playing: false,
  };

  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require('plyr')
      player = new Plyr('#player');
    }
  }

  play = () => {
    this.setState(prevState => ({
      playing: !prevState.playing,
    }));
    if(this.state.playing === false) {
      player.play();
    }else if(this.state.playing === true){
      player.pause();
    }
  };

  render() {
    const { data } = this.props;
    const { playing } = this.state;
    const { datoCmsPodcast: { title, podcastFile, description, meta: { createdAt }, podcastType, ytSpotifyLink } } = data;
    return (
      <div className={styles.playerWrap} style={{height: podcastType !== "mp3" ? "unset" :  "75%"}}>
        <div className={styles.infoWrap}>
          <button className={cs(styles.playBtn, {[styles.pause]: playing})} onClick={this.play}></button>
          <div className={styles.title}>
            <p className={styles.date}>{formatDate(createdAt)}</p>
            <h1 className={styles.podcastTitle}>{title}</h1>
            <p className={styles.description}>
              {description}
            </p>
          </div>
        </div>
        <div className={styles.reactPlayerWrap}>
            <audio id="player" controls>
              <source src={podcastFile.url} type="audio/mp3" />
            </audio>
      </div>
        </div>
    );
  }
}

Player.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Player;
