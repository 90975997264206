import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Player.module.scss';
import cs from 'classnames';
import formatDate from 'src/components/pages/podcasts/PodcastTile/formatDate';


let player;

class PlayerSpotify extends Component {
  state = {
    playing: false,
  };

  componentDidMount() {
    // if(typeof window !== "undefined" && typeof document !== "undefined") {
    //   const Plyr = require('plyr')
    //   player = new Plyr('#player');
    // }
  }

  play = () => {
    this.setState(prevState => ({
      playing: !prevState.playing,
    }));
    if(this.state.playing === false) {
      player.play();
    }else if(this.state.playing === true){
      player.pause();
    }
  };

  render() {
    const { data } = this.props;
    const { playing } = this.state;
    const { datoCmsPodcast: { title, description, meta: { createdAt }, podcastType, ytSpotifyLink } } = data;
    return (

      <div className={styles.playerBox}>
        <div className={styles.infoWrap}>
          <div className={styles.title}>
            <p className={styles.date}>{formatDate(createdAt)}</p>
            <h1 className={styles.podcastTitle}>{title}</h1>
            <p className={styles.description}>
              {description}
            </p>
          </div>
        </div>
        <div className={styles.reactPlayerWrap}>
          <div style={{height: "400px"}}>
            <iframe style={{borderRadius: "12px"}}
                    src="https://open.spotify.com/embed/track/6zW80jVqLtgSF1yCtGHiiD?utm_source=generator"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
            ></iframe>
          </div>
      </div>
        </div>
    );
  }
}

PlayerSpotify.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PlayerSpotify;
