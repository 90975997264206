import React from 'react';
import { graphql } from 'gatsby';
// import cs from 'classnames';
import styles from 'src/layouts/podcast/podcast.module.scss';
import SEO from 'src/components/seo';
import Layout from 'src/Theme/layout';
import Player from 'src/components/Player/Player';
import BackgroundImage from 'gatsby-background-image';
import PlayerYT from "../../components/Player/PlayerYT";
import PlayerSpotify from "../../components/Player/PlayerSpotify";


export const query = graphql`
    query podcast($id: String!) {
        datoCmsPodcast(id: {eq: $id}) {
            title
            description
            backgroundImage{
                alt
                fluid(maxWidth: 1500) {
                    ...GatsbyDatoCmsFluid_noBase64
                }

            }
            podcastFile{
                url
            }
            ytSpotifyLink
            podcastType
            meta {
                createdAt
            }
        }
    }
`;

const Podcast = ({ data }) => {
  const { datoCmsPodcast: { backgroundImage: { fluid }, podcastType } } = data;
  return (
    <Layout navWhite>
      <SEO title="Podcast"/>
      <BackgroundImage
        Tag={`header`}
        className={styles.header}
        fluid={fluid}
      >
        <div className={styles.layer}></div>
          {podcastType === "mp3" && (
              <Player data={data}/>)}
      </BackgroundImage>
        {podcastType === "youtube" && (
            <PlayerYT data={data}/>)}

        {podcastType === "spotify" && (
            <PlayerSpotify data={data}/>)}
    </Layout>
  );
};

export default Podcast;
